.forumPage {
  position: relative;

  .forumBannerContainer {
    margin-top: 50px;
    width: 100%;
    padding: 0 300px;
  }

  .forumMain {
    display: flex;
    flex-direction: row;
    margin: 50px 60px 100px 60px;
    justify-content: space-between;
    column-gap: 20px;

    .forumSideBarCcontainer {
      width: 25%;
    }

    .newsfeedContainer {
      display: flex;
      flex-direction: column;
      width: 50%;

      .newsfeedFiltersContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
        align-items: center;

        .newsfeedFilterButtonsContainer {
          display: flex;
          flex-direction: row;
          column-gap: 10px;
        }
      }

      .noPostsFoundContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $light;
        height: 500px;
        margin-top: 20px;
        border-radius: 25px;

        .noPostsFoundLabel {
          color: $primary;
          font-family: $mainFont;
          font-weight: lighter;
        }
      }

      .loadMorePostsButton {
        margin: auto;
        margin-top: 40px;
        background-color: transparent;
        border-width: 1px;
        width: fit-content;
      }
    }

    .forumAdditionalInfoContainer {
      display: flex;
      flex-direction: column;
      width: 25%;
      border-radius: 10px;
      row-gap: 20px;
    }
  }

  .loadingContainer {
    display: flex;
    width: 100%;
    height: 70vh;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1250px) {
    .forumBannerContainer {
      padding: 0 100px;
    }

    .forumMain {
      flex-direction: column;

      .forumSideBarCcontainer {
        display: none;
        width: 100%;
      }

      .newsfeedContainer {
        width: 100%;
        margin-top: 20px;
      }

      .forumAdditionalInfoContainer {
        display: none;
      }
    }
  }

  @media (max-width: 769px) {
    .forumBannerContainer {
      padding: 0 20px;
    }

    .forumMain {
      margin: 20px 0px 50px 0px;

      .forumSideBarCcontainer {
        display: none;
      }

      .newsfeedContainer {
        .forumSearchContainer {
          width: 100%;
        }

        .newsfeedFiltersContainer {
          flex-direction: column;
          align-items: normal;
          width: 95%;
          margin: auto;
          justify-content: space-between;
          margin-top: 20px;

          // margin-left: 15px;
          // margin-right: 15px;
          .newsfeedFilterButtonsContainer {
            justify-content: space-between;
            margin-top: 20px;
          }
        }
      }

      .forumAdditionalInfoContainer {}
    }
  }

  @media (max-width: 350px) {
    .forumMain {
      .newsfeedContainer {
        .newsfeedFiltersContainer {
          .newsfeedFilterButtonsContainer {
            margin-left: 0px;
            margin-right: 0px;
          }
        }
      }
    }
  }
}