.footer {
  // @extend .container;
  @extend .standardMargin;
  // display: flex;
  padding: 80px 0 0 0;
  border-top: 1px solid $primary;
  // justify-content: space-between;

  .contactsContainer {
    // display: flex;
    // flex-direction: column;
    // flex-wrap: wrap;
    // align-items: flex-start;
    // row-gap: 20px;
    // column-gap: 150px;
    // height: 80px;

    .singleContact {
      display: flex;
      flex-direction: row;
      column-gap: 15px;
      text-decoration: none;
      align-items: center;

      .iconContainer {
        width: 20px;
        height: 20px;
        object-fit: contain;
        width: fit-content;

        img {
          width: auto;
        }
      }

      .singleContactLabel {
        font-weight: 500;
        font-size: 15px;
        color: $primary;
        text-align: center;
        line-height: 1.25;
        width: fit-content;
      }
    }
  }

  p {
    font-size: 14px;
    color: #293896;
    line-height: 1.5;
  }

  h5 {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: bold;
    color: #293896;
    line-height: 1.5;
    text-transform: uppercase;
  }

  .footer-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 80px;
    border-bottom: 1px solid $primary;


    .footer-col-6 {
      width: 50%;
    }

    .footer-col {
      width: auto;
    }

    .footer-d-flex {
      display: flex;
      justify-content: flex-end;
    }
  }

  .footer-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
  }

  // .socialNetworkContactsContainer {
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: space-between;
  //   width: 100px;
  //   margin-top: 5px;

  //   .singleSocialMediaContact {

  //     display: flex;
  //     flex-direction: row;
  //     column-gap: 15px;
  //     text-decoration: none;
  //     align-items: flex-start;

  //     .iconContainer {
  //       width: auto;
  //       height: 30px;
  //       object-fit: contain;

  //       img {
  //         width: auto;
  //         height: 100%;
  //       }
  //     }
  //   }
  // }

  .additionalInfoContainer {
    display: flex;
    row-gap: 15px;
    flex-direction: column;

    .infoLabel {
      color: $primary;
      font-size: 13px;
      font-weight: 500;
    }
  }

  // a {
  //   position: relative;

  //   &:hover::after {
  //     width: 100%;
  //   }

  //   &::after {
  //     content: "";
  //     position: absolute;
  //     left: 0;
  //     bottom: -6px;
  //     width: 0;
  //     height: 1px;
  //     background-color: $primary;
  //     transition: all 0.3s ease-in-out;
  //   }
  // }
}



@media (max-width: 1200px) {
  .footer {
    align-items: flex-end;

    .contactsContainer {
      flex-wrap: nowrap;
      height: auto;
    }

    .footer-row {
      display: flex;
      flex-wrap: wrap;

      .footer-col-6 {
        width: 100%;
        margin-bottom: 30px;
      }

      .footer-col {
        width: 50%;
      }

      .footer-d-flex {
        display: block;
      }
    }

    .footer-bottom {
      flex-wrap: wrap;
    }
  }
}

@media (max-width: 1200px) {
  .footer {
    flex-direction: column;
    align-items: center;
    padding: 60px 0;

    .contactsContainer {
      align-items: center;
    }

    .socialNetworkContactsContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100px;
      margin-top: 25px;

      .singleSocialMediaContact {

        display: flex;
        flex-direction: row;
        column-gap: 15px;
        text-decoration: none;
        align-items: center;

        .iconContainer {
          width: auto;
          height: 30px;
          object-fit: contain;

          img {
            width: auto;
            height: 100%;
          }
        }
      }
    }

    .additionalInfoContainer {
      align-items: center;
      margin: 40px 0;
    }
  }
}