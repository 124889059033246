.calendar {
  overflow: hidden !important;
}
.fade.modal-backdrop.show {
  z-index: 9999;
}
.fade.modal.show {
  z-index: 9999;
}

.calendar-context-menu {
  z-index: 9999;
}

.pop-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.popup-content {
  position: fixed;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.d-flex {
    display: flex;
  }

  .error {
    margin: 5px 0 0 15px;
    color: red;
  }

  .popup-colse-btn {
    position: absolute;
    top: -20px;
    right: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 0;
    border-radius: 50%;
    background-color: #293896;
    color: #fff;
  }

  .popup-container {
    position: absolute;
    z-index: 2;
    width: 60%;
    padding: 50px 100px;
    background: #FFFFFF;
    border-radius: 45px;

    .scroll {
      overflow-y: scroll;
      max-height: 80vh;

      &::-webkit-scrollbar {
        width: 10px;
      }
    }

    .mb-6 {
      margin-bottom: 60px;
    }

    .mb-3 {
      margin-bottom: 30px;
    }

    .popup-row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: -10px -30px;

      .popup-col-4 {
        width: 25%;
        padding: 10px 30px;
      }

      .popup-col-6 {
        width: 50%;
        padding: 10px 30px;
      }

      &.no-wrap {
        align-items: inherit;
      }
    }

    .popup-title {
      margin-bottom: 30px;
      color: #293896;
      font-size: 25px;
      font-weight: 900;
      font-family: DejavuSans, sans-serif;
      text-transform: uppercase;
    }
    
    .popup-label {
      margin-bottom: 5px;
      font-size: 14px;
    }

    .input {
      display: block;
      position: relative;
      width: 100%;
      max-width: 100%;
      height: 40px;
      padding: 10px 15px;
      transition: all .15s ease-in-out;
      border: 1px solid #B9B9B9;
      border-radius: 20px;
      outline: none;
      background: #FFFFFF;
      color: #B9B9B9;
      font-family: 'FiraGO', sans-serif;
      font-size: 14px;
      line-height: 14px;

      &.select {
        background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
        background-position: calc(100% - 0.75rem) center !important;
        -moz-appearance: none !important;
        -webkit-appearance: none !important;
        appearance: none !important;
        padding-right: 15px !important;
      }

      &.textarea {
        height: 70px;
        min-height: 70px;
      }

      &:focus {
        border: 1px solid #293896;
        transition: all .15s ease-in-out;
      }

      &::placeholder {
        color: #B9B9B9;
        transition: all .15s ease-in-out;
      }
    }

    .popup-list {

      li {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 15px;
        padding-left: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          content: '';
          display: inline-block;
          position: absolute;
          top: 7px;
          left: 0;
          // transform: translatey(-5px);
          width: 4px;
          height: 10px;
          background: #293896;
          border-radius: 2px;
        }

        p {
          color: #293896;
          font-size: 15px;
          margin-bottom: 0;
        }
      }
    }

    .popup-btn {
      min-width: 150px;
    }

    .popup-table {

      .table-item-title {
        margin-bottom: 10px;
        color: #293896;
        font-size: 15px;
        font-weight: 900;
        font-family: DejavuSans, sans-serif;
        text-transform: uppercase;
      }

      .table-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        color: #293896;
        font-size: 14px;

        &.last-table-item {
          border-bottom: 1px solid rgba(0, 0, 0, 0);
          font-weight: 700;
          font-size: 16px;
        }
      }
    }

    .popup-terms {
      display: flex;
      align-items: center;
      color: #293896;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .popup-overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(6, 6, 6, 0.5);
  }
}

@media (max-width: 1200px) {
  .popup-content {

    .popup-colse-btn {
      right: 20px;
    }

    .popup-container {
      width: 95%;
      padding: 20px 15px;
      border-radius: 10px;

      .popup-row {
        margin: 0;

        .popup-col-4 {
          width: 50%;
          padding: 0;
          margin-bottom: 15px;
        }

        .popup-col-6 {
          width: 100%;
          padding: 0;
          margin-bottom: 15px;
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .popup-content {

    .popup-colse-btn {
      right: 20px;
    }

    .popup-container {
      width: 90%;
      padding: 30px 20px;
      border-radius: 10px;

      .popup-row {
        margin: 0;

        .popup-col-4 {
          width: 100%;
          padding: 0;
          margin-bottom: 15px;
        }

        .popup-col-6 {
          width: 100%;
          padding: 0;
          margin-bottom: 15px;
        }
      }
    }
  }
}