.aboutMainContainer {
  padding-top: 60px;
  padding-bottom: 150px;
}

.aboutBannerContent {
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;
  justify-content: center;
  column-gap: 30px;

  .aboutBannerImageContainer {
    display: flex;
    width: 20%;
    height: auto;
  }

  .aboutBannerLabel {
    display: flex;
    font-family: $mainFont;
    font-size: 96px;
    font-weight: 900;
    padding: 0;
    margin: 0;
    height: fit-content;
    margin-top: 14px;
    line-height: 0.75;
  }

  .aboutBannerLabelRed {
    color: $red;
  }

  .aboutBannerLabelBlue {
    color: $primary;
  }
}

.missionText {
  margin-bottom: 50px;
  font-size: 20px;
  font-weight: 500;
  font-family: $mainFont;
  color: $primary;
  line-height: 1.25;
}

.aboutOverSection {
  margin-bottom: 70px;
}

.SliderOverText {
  margin-bottom: 40px;
  margin-top: 40px;

  font-size: 20px;
  font-weight: 500;
  line-height: 1.25;
  font-family: $mainFont;
  color: $primary;
}

.missionBannerContent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;

  .missionBannerLabel {
    display: flex;
    font-family: $mainFont;
    font-size: 96px;
    font-weight: 900;
    padding: 0;
    margin: 0;
    height: fit-content;
    margin-top: 14px;
    line-height: 0.75;
    color: $red;
  }
}

.borderCenterd {
  padding: 0 5%;
}

@media (max-width: 992px) {
  .aboutMainContainer {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .aboutBannerContent {
    // margin-bottom: 50px;
    // display: flex;
    // flex-direction: row;
    // width: 100%;
    // align-items: flex-end;
    // justify-content: center;
    column-gap: 10px;

    .aboutBannerImageContainer {
      // display: flex;
      // width: 20%;
      // height: auto;
    }

    .aboutBannerLabel {
      // display: flex;
      // font-family: $mainFont;
      font-size: 48px;
      // font-weight: 900;
      // padding: 0;
      // margin: 0;
      // height: fit-content;
      // margin-top: 14px;
      // line-height: 0.75;
    }

    .aboutBannerLabelRed {
      // color: $red;
    }

    .aboutBannerLabelBlue {
      // color: $primary;
    }
  }

  .missionText {
    // margin-bottom: 50px;
    // font-size: 20px;
    // font-weight: 500;
    // font-family: $mainFont;
  }

  .aboutOverSection {
    // margin-bottom: 70px;
  }

  .SliderOverText {
    // margin-bottom: 50px;
    // font-size: 20px;
    // font-weight: 500;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;
  }

  .missionBannerContent {
    // width: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // padding: 50px 0;
    .missionBannerLabel {
      // display: flex;
      // font-family: $mainFont;
      font-size: 48px;

      // font-weight: 900;
      // padding: 0;
      // margin: 0;
      // height: fit-content;
      // margin-top: 14px;
      // line-height: 0.75;
      // color: $red;
    }
  }
}

@media (max-width: 520px) {
  .aboutMainContainer {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .aboutBannerContent {
    margin-bottom: 20px;

    // display: flex;
    // flex-direction: row;
    // width: 100%;
    // align-items: flex-end;
    // justify-content: center;
    // column-gap: 10px;
    .aboutBannerImageContainer {
      // display: flex;
      // width: 20%;
      // height: auto;
    }

    .aboutBannerLabel {
      // display: flex;
      // font-family: $mainFont;
      font-size: 28px;
      // font-weight: 900;
      // padding: 0;
      // margin: 0;
      // height: fit-content;
      // margin-top: 14px;
      // line-height: 0.75;
    }

    .aboutBannerLabelRed {
      // color: $red;
    }

    .aboutBannerLabelBlue {
      // color: $primary;
    }
  }

  .missionText {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    // font-weight: 500;
    // font-family: $mainFont;
  }

  .aboutOverSection {
    // margin-bottom: 70px;
  }

  .SliderOverText {
    margin-bottom: 20px;
    font-size: 14px;
    margin-top: 10px;
    line-height: 1.5;
    text-align: center;
    // font-weight: 500;
  }

  .missionBannerContent {
    // width: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    padding: 20px 0;

    .missionBannerLabel {
      // display: flex;
      // font-family: $mainFont;
      font-size: 40px;

      // font-weight: 900;
      // padding: 0;
      // margin: 0;
      // height: fit-content;
      // margin-top: 14px;
      // line-height: 0.75;
      // color: $red;
    }
  }
}
