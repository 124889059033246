.profilePage {
  .profileBannersContainer {
    display: flex;
    display: flex;
    padding: 0 75px;
    flex-direction: column;

    .coverPhotoContainer {
      display: flex;
      object-fit: contain;
      position: relative;
      cursor: pointer;

      img {
        border-radius: 45px;
        border: 2px solid $light;
        height: 385px;
      }
    }

    .profilePhotoAndNameContainer {
      display: flex;
      flex-direction: row;

      .profilePhotoContainer {
        display: flex;
        position: relative;
        margin-top: -100px;
        padding-left: 75px;

        .profileImage {
          display: flex;
          position: relative;
          width: 200px;
          height: 200px;
          border: none;
          object-fit: cover;
          aspect-ratio: 1/1;
          background-color: transparent;
          cursor: pointer;

          img {
            border-radius: 100px;
            border: 3px solid $light;
          }
        }
      }

      .nameContainer {
        display: flex;
        padding: 0px 5px 0px 20px;
        height: 100px;
        align-items: center;
        margin-top: -20px;
        justify-content: flex-start;

        .name {
          display: flex;
          margin-right: 50px;
          padding-top: 11px;
          padding-left: 5px;
          font-size: 40px;
          font-weight: 900;
          color: $primary;
        }
      }
    }
  }

  .userInformationContainer {
    margin-left: auto;
    margin-right: auto;
    padding-left: 150px;
    padding-right: 150px;
    padding-top: 60px;
    padding-bottom: 0px;

    .userSkillsLabel {
      margin-bottom: 30px;
      font-size: 25px;
      font-weight: 700;
      font-family: $mainFont;
      color: $primary;
    }

    .userSkillsContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 50px;

      .usersProfileSkills {
        font-size: 14px;
        font-weight: 900;
      }
    }

    .userProfileContactsContainer {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 50px;
      width: 70%;
      row-gap: 25px;

      .userProfileContactItem {
        display: flex;
        align-items: center;
        width: 350px;

        .profileIconContainer {
          width: 25px;
          margin-right: 10px;

          img {
            display: block;
          }
        }

        .profileContactsText {
          color: $primary;
          font-size: 18px;
          font-weight: 400;
        }
      }
    }

    .profileAboutContent {
      display: flex;
      flex-direction: column;

      .profileAboutLabel {
        margin-bottom: 30px;
        color: $primary;
        font-size: 25px;
        font-weight: 900;
        font-family: $mainFont;
        text-transform: uppercase;
      }

      .profileAboutText {
        margin-bottom: 60px;
        color: $primary;
        font-family: $mainFont;
        line-height: 23px;
        font-size: 14px;
      }
    }

    .profileSliderContainer {
      margin-bottom: 60px;
    }
  }

  .profileCardsContainer {
    margin-top: 60px;
    padding-left: 150px;
    padding-right: 150px;

    .profileCardsLabel {
      margin-bottom: 30px;
      color: $primary;
      font-size: 25px;
      font-weight: 900;
      font-family: $mainFont;
    }

    .profileCards {
      position: relative;
      width: 100%;
    }

    .showMorePostsButtonContainer {
      margin: 0 0 40px 0;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;

      .showMorePostsButton {
        padding: 17px 27px;
        border: 2px solid $primary;
        border-radius: 28px;
        background-color: transparent;
        font-size: 15px;
        font-weight: 600;

        &:active {
          background-color: $primary;
          color: $light;
        }
      }
    }
  }

  .postCardsContent {
    column-count: 3;
    margin-bottom: 40px;
  }
}

@media (max-width: 1500px) {
  .profilePage {
    .profileBannersContainer {
      .coverPhotoContainer {
        img {
        }
      }

      .profilePhotoAndNameContainer {
        .profilePhotoContainer {
          .profileImage {
          }
        }

        .nameContainer {
          .name {
          }
        }
      }
    }

    .userInformationContainer {
      .userProfileContactsContainer {
        .userProfileContactItem {
        }
      }
    }

    .profileCardsLabel {
    }

    .profileCardsContainer {
      .postCardsContent {
        column-count: 2;
      }
    }
  }
}

@media (max-width: 950px) {
  .profilePage {
    .profileBannersContainer {
      padding: 0;

      .coverPhotoContainer {
        img {
          border-radius: 0px;
          height: 385px;
          border-left: none;
          border-right: none;
        }
      }

      .profilePhotoAndNameContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .profilePhotoContainer {
          margin-top: -105px;
          padding-left: 0px;

          .profileImage {
            width: 200px;
            height: 200px;
          }
        }

        .nameContainer {
          display: flex;
          align-items: center;
          padding-left: 0px;
          padding: 10px 0 0 0;

          .name {
            font-size: 30px;
            padding: 0;
            margin: 0;
          }
        }
      }
    }

    .userInformationContainer {
      padding: 30px 30px 50px 30px;

      .userProfileContactsContainer {
        width: 100%;
        column-gap: 25px;

        .userProfileContactItem {
          width: 300px;
        }
      }
    }

    .profileCardsLabel {
      padding-left: 0px;
    }

    .profileCardsContainer {
      margin-top: 30px;
      padding-left: 30px;
      padding-right: 30px;

      .postCardsContent {
        column-count: 2;
      }
    }
  }
}

@media (max-width: 500px) {
  .profilePage {
    .profileBannersContainer {
      padding: 0;

      .coverPhotoContainer {
        img {
          border-radius: 0px;
          height: 200px;
        }
      }

      .profilePhotoAndNameContainer {
        .profilePhotoContainer {
          .profileImage {
            margin: 0;
            margin-right: 0;
            margin-top: 20px;
            width: 150px;
            height: 150px;

            img {
              width: 150px;
              height: 150px;
            }
          }
        }

        .nameContainer {
          margin-bottom: 30px;
          align-items: center;
          justify-content: center;
          text-align: center;
          line-height: 1.25;

          .name {
            font-size: 25px;
          }
        }
      }
    }

    .userInformationContainer {
      padding-top: 0px;

      .userSkillsLabel {
        font-size: 20px;
      }

      .userSkillsContainer {
        .usersProfileSkills {
        }
      }

      .userProfileContactsContainer {
        width: 100%;
        column-gap: 25px;
        row-gap: 25px;

        .userProfileContactItem {
          width: 300px;

          .profileIconContainer {
            width: 22.5px;

            img {
            }
          }

          .profileContactsText {
            font-size: 17.5px;
          }
        }
      }

      .profileAboutContent {
        .profileAboutLabel {
          font-size: 20px;
        }

        .profileAboutText {
          margin-bottom: 30px;
        }
      }

      .profileSliderContainer {
        margin-bottom: 0px;
      }
    }

    .profileCardsContainer {
      padding-left: 0px;
      padding-right: 0px;

      .profileCardsLabel {
        font-size: 20px;
        padding-left: 30px;
      }

      .postCardsContent {
        column-count: 1;
      }
    }
  }
}
