.passwordResetWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: #fff;
}

.passwordResetCenter {
  width: 100%;
}

.passwordResetContainer {
  margin: 0 auto;
  padding: 40px;
  box-shadow: 0px 4px 4px rgba(41, 56, 150, 0.1);
  border-radius: 30px;
}

.passwordResetContent {
  padding: 40px 25px;
}

.passwordResetTitle {
  margin-bottom: 50px;
  font-size: 20px;
  font-weight: 700;
  font-family: $mainFont;
  text-align: center;
}

.passsordResetFlexible {
  display: flex;
  flex-wrap: wrap;
}

.passwordResetCol {
  width: 100%;
  margin-bottom: 25px;

  a {
    display: block;
    width: 100%;
    padding: 19px;
    border: 2px solid $primary;
    border-radius: 45px;
    background-color: $primary;
    text-align: center;

    span {
      font-size: 14px;
      font-weight: 700;
    }
  }

  input {
    width: 100%;
    padding: 16px 20px;
    border: 2px solid #e5e5e5;
    border-radius: 45px;
    color: #293896;
    font-size: 18px;
    font-weight: 700;
    font-family: $mainFont;

    &::placeholder {
      color: $primary;
      font-size: 13px;
      font-weight: 700;
      font-family: $mainFont;
    }
  }

  input[type="submit"] {
    background-color: $primary;
    color: $light;
    cursor: pointer;
  }
}

.passwordResetText {
  width: 100%;
  margin-bottom: 45px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

@media (max-width: 600px) {
  .passwordResetContainer {
    margin: 0 auto;
    padding: 40px 10px;
    box-shadow: 0px 4px 4px rgba(41, 56, 150, 0.1);
    border-radius: 30px;
  }

  .passwordResetContent {
    padding: 40px 10px;
  }

  .passwordResetCol {
    input {
      padding: 14px 14px;
    }
  }
}
