.editProfileCoverPhoto {
  height: 385px;
}

.editProfilePhotoOptionsLabel {
  color: $primary;
  font-family: $mainFont;
  text-align: center;
}

input[type="file"] {
  display: none;
}

.editProfilePage {
  display: flex;
  flex-direction: column;

  .editProfileMainUserBanner {
    display: flex;
    padding: 0 5%;
    flex-direction: column;

    .clickableCoverPhoto {
      cursor: pointer;
    }

    .editCoverPhotoContainer {
      display: flex;
      object-fit: contain;
      height: 385px;
      position: relative;
      cursor: pointer;

      .editProfileCoverPhoto {
        border-radius: 45px;
        border: 2px solid $light;
      }

      ///--------------------------STYLE FOR DROP DOWN OVER COVER IMAGE ---------------////
      .editProfileCoverIcon {
        // bottom: 0%;
        // right: 20px;
        // display: flex;
        // position: absolute;
        // width: fit-content;
        // background-color: red;
        // justify-content: flex-start;
        position: absolute;
        bottom: 10px;
        right: 20px;
        width: 250px;
        display: flex;

        align-items: flex-start;
        justify-content: center;

        .photoOptionsContent {
          width: 100%;

          .photoProfileCenter {
            justify-content: flex-end;
            display: flex;
            width: 100%;
            margin-bottom: 15px;

            .photoprofileContent {
              border: none;
              cursor: pointer;
              width: 35px;
              background-color: $light;
              height: 35px;
              padding: 7px;
              border-radius: 50px;
            }
          }

          .photoOptions {
            position: absolute;
            display: flex;
            flex-direction: column;
            right: -10px;
            padding: 15px;
            border-radius: 15px;
            background-color: $light;
            visibility: hidden;
            border: 1px solid #293896;

            &.visible::after {
              content: "";
              position: absolute;
              z-index: 0;
              width: 14px;
              height: 14px;
              background-color: white;
              top: -8px;
              right: 20px;
              transform: rotate(45deg);
              border-top: 1px solid #293896;
              border-left: 1px solid #293896;
            }

            &.visible {
              display: block;
              visibility: visible;
              z-index: 9999;
            }

            p {
              font-size: 17px;
              font-weight: 400;
            }

            .attachFileButton {
              display: flex;
              align-items: center;
              position: relative;
              padding: 5px;
              border: none;
              background-color: transparent;

              &:hover::after {
                width: 100%;
              }

              &::after {
                content: "";
                display: inline-flex;
                position: absolute;
                left: 0;
                bottom: 2px;
                width: 0;
                height: 1px;
                background-color: $primary;
                transition: all 0.3s ease-in-out;
              }
            }

            input[type="file"] {
              display: none;
            }
          }
        }
      }
    }

    .editProfilePhotoAndNameContainer {
      display: flex;
      flex-direction: row;

      .editProfilePhotoContainer {
        display: flex;
        position: relative;
        margin-top: -100px;
        padding-left: 100px;

        .loadingProfilePictureContainer {
          display: flex;
          width: 200px;
          height: 200px;
          border-radius: 100px;
          background-color: $light;
        }

        .editProfileImage {
          display: flex;
          position: relative;
          width: 200px;
          height: 200px;
          border: none;
          cursor: pointer;
          object-fit: cover;
          aspect-ratio: 1/1;
          background-color: transparent;

          img {
            border-radius: 100px;
            border: 3px solid $light;
          }
        }


        .editProfilePictureIcon {
          // bottom: 0%;
          // right: 20px;
          // display: flex;
          // position: absolute;
          // width: fit-content;
          // background-color: red;
          // justify-content: flex-start;
          position: absolute;
          bottom: 10px;
          right: 5px;
          width: 250px;
          display: flex;
          align-items: flex-start;
          justify-content: center;

          .photoOptionsContent {
            width: 100%;

            .photoProfileCenter {
              justify-content: flex-end;
              display: flex;
              width: 100%;
              margin-bottom: 15px;

              .photoprofileContent {
                border: none;
                width: 35px;
                height: 35px;
                cursor: pointer;
                background-color: $light;
                padding: 7px;
                border-radius: 50px;
              }
            }

            .photoOptions {
              position: absolute;
              display: flex;
              flex-direction: column;
              right: -10px;
              padding: 15px;
              border-radius: 15px;
              background-color: $light;
              visibility: hidden;
              border: 1px solid #293896;

              &.visible::after {
                content: "";
                position: absolute;
                z-index: 0;
                width: 14px;
                height: 14px;
                background-color: white;
                top: -8px;
                right: 20px;
                transform: rotate(45deg);
                border-top: 1px solid #293896;
                border-left: 1px solid #293896;
              }

              &.visible {
                display: block;
                visibility: visible;
                z-index: 9999;
              }

              p {
                font-size: 17px;
                font-weight: 400;
              }

              .attachFileButton {
                display: flex;
                align-items: center;
                position: relative;
                padding: 5px;
                border: none;
                background-color: transparent;

                &:hover::after {
                  width: 100%;
                }

                &::after {
                  content: "";
                  display: inline-flex;
                  position: absolute;
                  left: 0;
                  bottom: 2px;
                  width: 0;
                  height: 1px;
                  background-color: $primary;
                  transition: all 0.3s ease-in-out;
                }
              }

              input[type="file"] {
                display: none;
              }
            }
          }
        }
      }

      .editNameContainer {
        display: flex;
        padding: 0px 5px 0px 20px;
        //width: 100%;
        height: 100px;
        align-items: center;
        margin-top: -20px;
        justify-content: flex-start;

        .editUpdateContent {
          display: flex;
          margin-right: 50px;
          width: fit-content;
          z-index: 9998;
          //width: 90%;

          input {
            margin-top: 10px;
            width: 100%;
            padding: 10px 15px;
            font-size: 25px;
            border: none;
            border-radius: 25px;
            font-family: $mainFont;
            color: $primary;
            font-weight: 900;
            outline: none;
            border: 1px solid #293896;
            border-radius: 25px;
          }
        }

        .editProfileUserName {
          display: flex;
          margin-right: 50px;
          padding-top: 11px;
          padding-left: 5px;
          font-size: 40px;
          font-weight: 900;
          color: $primary;
        }

        .editProfileBtn {
          display: flex;
          border: none;
          padding-top: 11px;
          background-color: transparent;
          align-items: center;
          width: 50px;
          height: 50px;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          img {
            width: 30px;
            height: auto;
          }
        }
      }

      .profilesCheduledtime {
        display: flex;
        align-items: center;
        margin-top: 15px;
        margin-left: auto;

        .mr-2 {
          margin-right: 20px;
        }
      }
    }
  }

  .editProfileUserSettings {
    padding: 60px calc(5% + 100px) 100px calc(5% + 100px);

    .editProfileActivitiesLabel {
      font-family: $mainFont;
      margin-bottom: 30px;
      font-size: 25px;
      font-weight: 700;
      color: $primary;
      text-transform: uppercase;
    }

    .selectableSkillsContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 50px;

      .selectableSkill {
        font-size: 14px;
        font-weight: 900;
      }
    }

    .editFormContainer {
      display: flex;
      // flex-direction: column;
      flex-wrap: wrap;

      color: $primary;
      font-size: 25px;
      font-weight: 900;

      .mainTextInputsContainer {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 60px;

        .contactsAndLanguagesInputsContainer {
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
          column-gap: 60px;
          row-gap: 30px;
          margin-bottom: 60px;

          .singleInputsContainer {

            label {
              display: block;


              .contactInfoTitle {
                display: inline-block;
                margin-bottom: 30px;
                text-transform: uppercase;
                color: $primary;
                font-family: $mainFont;
              }


              .inputIconContainer {
                display: flex;
                align-items: center;
                position: relative;
                margin-bottom: 10px;
                border: 1px solid $primary;
                border-radius: 25px;
                background-color: $light;

                .inputIcon {
                  width: 25px;
                  margin-left: 15px;

                  img {}

                }

                input {
                  width: 100%;
                  padding: 10px 15px;
                  border: none;
                  border-radius: 25px;
                  color: $primary;
                  font-size: 13px;
                  font-weight: 400;

                  &::placeholder {
                    color: $primary;
                    font-size: 13px;
                    font-weight: 500;
                    font-family: $mainFont;
                  }
                }
              }
            }
          }
        }

        .galleryUploadContainer {
          width: 100%;
          margin-bottom: 50px;

          .uploadGalleryButton {
            display: flex;
            cursor: pointer;
            flex-direction: row;
            font-size: 25px;
            width: fit-content;
            font-weight: 600;
            font-family: $mainFont;
            text-transform: uppercase;
            align-items: flex-start;
            color: $primary;
            border: none;
            margin-right: 32px;

            input[type="file"] {
              display: none;
            }

            img {
              width: auto;
              height: 25px;
              margin-left: 20px;
            }

            .uploadGalleryInformation {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
            }

            .photosSelected {
              font-size: 9px;
              margin-top: 2.5px;
              font-weight: 400;
            }
          }
        }

        .textAreaColumn {
          display: flex;
          width: 100%;
          margin-bottom: 60px;

          label {
            width: 100%;

            .aboutYouLabel {
              display: inline-block;
              font-size: 25px;
              margin-bottom: 30px;
              text-transform: uppercase;
              color: $primary;
              font-family: $mainFont;
            }

            .aboutUserTextArea {
              width: 100%;
              height: 378px;
              padding: 20px;
              line-height: 1.4;
              border: 1px solid $light;
              border-radius: 10px;
              background-color: $light;
              color: $primary;
              font-size: 18px;
              font-weight: 400;
              font-family: $mainFont;
              box-shadow: 0px 4px 4px rgba(41, 56, 150, 0.05);
              resize: none;
            }
          }


        }

        .editSubmitButtonContainer {
          width: 100%;
          text-align: right;

          .editSubmitButton {
            padding: 10px 16px;
            border: 2px solid $primary;
            border-radius: 28px;
            background-color: $primary;
            color: $middleLight;
            font-size: 15px;
            font-weight: 800;
            font-family: $mainFont;
          }

          .editSubmitButton:active {
            background-color: $middleLight;
            color: $primary;
            border: 1px solid $primary;
          }
        }
      }
    }
  }
}

@media (max-width: 950px) {
  .editProfileCoverPhoto {
    // height: 385px;
  }

  .editProfilePage {


    .editProfileMainUserBanner {
      padding: 0;

      .clickableCoverPhoto {}

      .editCoverPhotoContainer {


        .editProfileCoverPhoto {
          border-radius: 0px;
          border: none;
        }

        .editProfileCoverIcon {

          bottom: 10px;


          .photoOptionsContent {

            .photoProfileCenter {


              .photoprofileContent {}
            }

            .photoOptions {


              &.visible::after {
                content: "";

              }

              &.visible {}

              p {}

              .attachFileButton {


                &:hover::after {}

                &::after {
                  content: "";

                }
              }

              input[type="file"] {
                // display: none;
              }
            }
          }
        }
      }

      .editProfilePhotoAndNameContainer {

        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;

        .editProfilePhotoContainer {

          padding-left: 0px;

          .editProfileImage {


            img {}
          }


          .editProfilePhotoOptionsContainer {


            bottom: 15px;
            left: 55px;


            .photoOptionsContent {


              .photoProfileCenter {


                .photoprofileContent {}
              }

              .photoOptions {


                &.visible::after {
                  content: "";

                }

                &.visible {}

                p {}

                .attachFileButton {


                  &:hover::after {}

                  &::after {
                    content: "";

                  }
                }

                input[type="file"] {}
              }

              .photoOptionsContent {}
            }
          }
        }

        .editNameContainer {
          display: flex;
          flex-direction: column;

          padding: 0;
          margin-top: 10px;
          justify-content: center;
          width: 100%;

          .editUpdateContent {
            margin-right: 0px;

            width: 100%;

            input {

              padding: 10px auto;

              border-radius: 0px;
              text-align: center;

              border-left: none;
              border-right: none;

            }
          }

          .editProfileUserName {

            margin: 0;
            font-size: 30px;

          }

          .editProfileBtn {
            position: relative;
            right: 5px;

            img {}
          }
        }

        .profilesCheduledtime {
          margin: 0 auto;
          padding-top: 10px;
        }
      }
    }

    .editProfileUserSettings {
      padding: 0px 20px;

      .editProfileActivitiesLabel {}

      .selectableSkillsContainer {


        .selectableSkill {}
      }

      .editFormContainer {


        .mainTextInputsContainer {

          .contactsAndLanguagesInputsContainer {
            flex-direction: row;
            width: 100%;

            .singleInputsContainer {
              label {

                .contactInfoTitle {}


                .inputIconContainer {


                  .inputIcon {

                    img {}

                  }

                  input {

                    &::placeholder {}
                  }
                }
              }
            }
          }


          .textAreaColumn {


            label {

              .aboutYouLabel {}

              .aboutUserTextArea {}
            }


          }

          .editSaveBtn {


            .editSbmBtn {}
          }
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .editProfileCoverPhoto {
    height: 200px;
  }

  .editProfilePage {

    .editProfileMainUserBanner {
      padding: 0;

      .clickableCoverPhoto {}

      .editCoverPhotoContainer {
        height: 200px;

        .editProfileCoverPhoto {
          border-radius: 0px;
          border: none;
        }

        .editProfileCoverIcon {
          bottom: 10px;

          .photoOptionsContent {

            .photoProfileCenter {

              .photoprofileContent {}
            }

            .photoOptions {


              &.visible::after {
                content: "";

              }

              &.visible {}

              p {}

              .attachFileButton {


                &:hover::after {}

                &::after {
                  content: "";

                }
              }

              input[type="file"] {}
            }
          }
        }
      }

      .editProfilePhotoAndNameContainer {
        margin-top: 150px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;

        .editProfilePhotoContainer {
          margin-top: -225px;
          padding-left: 0px;

          .editProfileImage {
            width: 150px;
            height: 150px;

            img {
              width: 150px;
              height: 150px;
            }
          }

          .editProfilePictureIcon {
            right: 0;
          }

        }

        .editNameContainer {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          padding: 0;
          margin-top: 10px;
          justify-content: center;
          width: 100%;

          .editUpdateContent {
            margin-right: 0px;
            font-size: 20px;
            width: 100%;

            input {
              font-size: 20px;
              padding: 10px auto;

              border-radius: 0px;
              text-align: center;

              border-left: none;
              border-right: none;

            }
          }

          .editProfileUserName {

            font-size: 25px;

          }

          .editProfileBtn {
            position: relative;

            right: 5px;

            img {}
          }
        }
      }
    }

    .editProfileUserSettings {
      padding: 0px 20px;

      .editProfileActivitiesLabel {
        font-size: 20px;
      }

      .selectableSkillsContainer {

        .selectableSkill {}
      }

      .editFormContainer {


        .mainTextInputsContainer {

          .contactsAndLanguagesInputsContainer {
            flex-direction: row;
            width: 100%;
            margin-bottom: 50px;

            .singleInputsContainer {
              label {


                .contactInfoTitle {
                  font-size: 20px;
                }


                .inputIconContainer {


                  .inputIcon {


                    img {}

                  }

                  input {


                    &::placeholder {}
                  }
                }
              }
            }
          }

          .galleryUploadContainer {


            .uploadGalleryButton {
              font-size: 20px;

              img {
                width: auto;
                height: 20px;
                margin-left: 10px;
              }

              .uploadGalleryInformation {}

              .photosSelected {}
            }
          }

          .textAreaColumn {


            label {

              .aboutYouLabel {}

              .aboutUserTextArea {}
            }


          }

          .editSaveBtn {

            .editSbmBtn {}
          }
        }
      }
    }
  }
}