header {
  // position: $p_relative;
  width: 100%;
}

.headerLanguageContainer {
  display: flex;
  width: auto;
  height: auto;
  align-items: center;
  justify-content: center;
  border: none;

  img {
    width: 30px;
    height: auto;
  }
}

.headerContainer {
  padding: 25px 60px 25px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .headerLogoContainer {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    width: 35%;
    align-items: center;

    .headerLogo {
      display: flex;
      width: 60px;
      height: fit-content;

      img {
        object-fit: fill;
        width: 100%;
        height: auto;
      }
    }
  }

  .headerMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    column-gap: 60px;
    position: absolute;
    left: 37.5%;
    .headerOption {
      a {
        position: relative;
        display: block;
        color: $primary;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        line-height: 1.25;
        padding: 0;
        margin: 0;
        box-shadow: 0;
      }
    }
  }

  .userLoginHeaderContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    column-gap: 30px;
    width: 35%;

    .headerMainButton {
      background-color: $primary;
      padding: 8px 18px 7px 18px;
      align-items: center;
      color: $light;
      border: 2px solid #293896;
      border-radius: 28px;
      font-size: 14px;
      font-weight: 600;
      font-family: $mainFont;
      text-align: center;
    }

    .notificationIconContainer {
      height: 30px;
      width: auto;
    }

    .loggedInUserImageContainer {
      display: flex;
      width: 50px;
      height: 50px;
      object-fit: cover;
      cursor: pointer;

      img {
        aspect-ratio: 1/1;
        border-radius: 50px;
      }
    }

    .additionalSettingsDropdownContainer {
      position: relative;

      .arrowReversed {
        transform: rotate(180deg);
      }

      .additionalSettingsDropdownButton {
        background-color: transparent;
        border: none;
        padding: 20px;
        cursor: pointer;
        margin-left: -30px;
      }

      .additionalSettingsDropdown {
        &.visible::after {
          content: "";
          position: absolute;
          z-index: 0;
          width: 14px;
          height: 14px;
          background-color: white;
          top: -8px;
          right: 2.5px;
          transform: rotate(45deg);
          border-top: 1px solid #293896;
          border-left: 1px solid #293896;
        }
      }

      .additionalSettingsDropdown {
        display: none;
        position: absolute;
        width: 125px;
        margin-top: 5px;
        margin-left: -120px;
        padding: 16px 17px;
        border-radius: 5px;
        border-top-right-radius: 0px;
        background-color: $light;
        visibility: hidden;
        border: 1px solid #293896;

        &.visible {
          display: flex;
          flex-direction: column;
          visibility: visible;
          align-items: center;
          row-gap: 10px;
          z-index: 9999;
        }

        .additionalSettingsNavigation {
          font-size: 14px;
          letter-spacing: 0.25px;
          font-weight: 700;
          color: $primary;
          margin-top: 5px;
          background-color: transparent;
          border: none;
          font-family: $mainFont;
          text-align: center;
        }
      }
    }
  }

  .sideBarBurger {
    display: none;
    width: 35px;
    border: none;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    z-index: 9999;

    &.btn-translate {
      transform: rotate(180deg);
    }

    &.btn-retranslate {
      transform: rotate(0);
    }
  }
}

//<--------------------------------------Mobile Styles--------------------------------->

.mobile-navbar {
  //display: none;
  position: fixed;
  top: 0;
  right: -70%;
  width: 70%;
  height: 100%;
  background-color: rgb(238, 235, 235);
  z-index: 99999999999999999999;
  animation-name: hide;
  animation-duration: 0.5s;
  transition: all 0.3s ease-in-out;

  &.display {
    //display: block;
    right: 0;
    animation-name: show;
    animation-duration: 0.5s;
  }

  .mobileCloseBtnContainer {
    width: 100%;
    padding: 30px 20px;
    text-align: end;

    & button {
      width: 35px;
      border: none;
      background-color: transparent;
    }
  }

  .mobile-navbar-area {
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding: 0 20px;

    .mobile-area-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;

      .mobile-item-spacing {
        margin-bottom: 40px;
      }

      .mobileLogOutButton {
        position: relative;
        display: block;
        padding: 10px;
        color: $primary;
        font-size: 16px;
        font-weight: bold;
        font-family: $mainFont;
        background-color: transparent;
        border: none;
        margin-bottom: 30px;
      }

      li {
        margin-bottom: 30px;

        a {
          position: relative;
          display: block;
          padding: 10px;
          color: $primary;
          font-size: 16px;
          font-weight: bold;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 0;
            height: 1px;
            background-color: $primary;
            transition: all 0.3s ease-in-out;
          }

          &:hover::after {
            width: 100%;
          }

          &.active::after {
            width: 100%;
          }
        }
      }

      .mobileUserLoginHeaderContainer {
        margin-top: 20px;

        .mobileHeaderMainButton {
          background-color: $primary;
          padding: 10px 23px;
          color: $light;
          border: 2px solid #293896;
          border-radius: 28px;
          font-size: 15px;
          font-weight: 800;
          font-family: $mainFont;
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 1150px) {
  .header-container {
    padding: 0 4%;
  }
}

@media (max-width: 1200px) {
  .headerContainer {
    padding: 25px 10px 25px 10px;

    .headerMenu {
      display: none;
    }

    .userLoginHeaderContainer {
      display: none;
    }

    .sideBarBurger {
      display: block;
    }
  }
}

@media (max-width: 500px) {
  .headerContainer {
    padding: 10px;

    .headerLogoContainer {
      .headerLogo {
        display: flex;

        img {
          object-fit: fill;
          width: 50px;
        }
      }
    }
  }

  .mobile-area-menu {
    align-items: flex-end;
  }
}

// @media (min-width: 992px) {
//   .mobile-navbar {
//     &.display {
//       display: none;
//     }

//     .sideBarBurger {
//       display: none;
//     }
//   }
// }
