.errorMessageLabel {
  font-size: 11px;
  color: red;
  text-transform: uppercase;
}

.passwordResetPage {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;

  .passwordResetModal {
    display: flex;
    width: 40%;
    padding: 60px 60px 100px 60px;
    flex-direction: column;
    align-items: flex-end;
    background-color: white;
    border-radius: 30px;

    .passwordResetForm {
      display: flex;
      width: 100%;
      height: 250px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 20px;

      .passwordRecoveredLabel {
        font-size: 14px;
        font-weight: 700;
        color: $primary;
      }

      .modalLabel {
        font-weight: 700;
        font-size: 20px;
        color: $primary;
        font-family: $mainFont;
        margin-bottom: 20px;
      }

      .passwordResetInputsContainer {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        row-gap: 10px;

        .singleInputContainer {
          display: flex;
          width: 90%;
          position: relative;
          align-items: center;

          .passwordResetInput {
            width: 100%;
            font-size: 13px;
            padding: 16px 20px;
            border-radius: 45px;
            border: 2px solid $darkGrey;
            color: $primary;
            background-color: #f7f7f7;
            font-weight: 700;
            font-family: $mainFont;
          }

          .passwordResetInput::placeholder {
            color: $darkGrey;
            opacity: 1;
          }

          .forgotPasswordButton {
            position: absolute;
            margin: 16px 20px 16px 0;
            border: none;
            font-size: 13px;
            font-weight: 700;
            text-transform: uppercase;
            font-family: $mainFont;
            border-radius: 5px;
            background-color: transparent;
            color: $primary;
            right: 0px;
            z-index: 2;
          }
        }

        .passwordRecoveryLabel {
          font-size: 14px;
          font-weight: 400;
          color: $primary;
          margin-top: 15px;
        }

        .errorMessageLabel {
          font-size: 11px;
          color: red;
          text-transform: uppercase;
        }
      }

      .resetPasswordSubmitButton {
        display: flex;
        margin-top: 30px;
        width: 90%;
        background-color: $primary;
        padding: 16px;
        align-items: center;
        border-radius: 45px;
        color: white;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 14px;
        justify-content: center;
        border: none;
        font-family: $mainFont;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 1250px) {
  .passwordResetPage {
    .passwordResetModal {
      width: 70%;
    }
  }
}

@media (max-width: 769px) {
  .passwordResetPage {
    .passwordResetModal {
      width: 100%;
      padding: 60px 0px 100px 0px;
      border-radius: 0px;

      .singleInputContainer {
        width: 100%;

        .passwordResetInput {
          width: 100%;
        }
      }

      .passwordReset {
        .resetPasswordSubmitButton {
          width: 90%;
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .passwordResetPage {
    .passwordResetModal {
      padding: 60px 10px 100px 10px;

      .passwordReset {
        .passwordRecoveredLabel {
          font-size: 11px;
        }

        .modalLabel {
          font-size: 18px;
        }

        .passwordResetInputsContainer {
          .singleInputContainer {
            width: 100%;

            .loginInput {
              border-radius: 45px;
            }
          }

          .passwordRecoveryLabel {
            font-size: 11px;
          }
        }
      }
    }
  }
}