.changePasswordContainer {
  display: flex;
  margin-top: 50px;

  .changePasswordHeader {
    font-size: 28px;
    font-weight: 900;
    color: $primary;
    text-transform: uppercase;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
  }

  .changePasswordForm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 30px;
    align-items: flex-end;

    .changePasswordLabel {
      display: flex;
      flex-direction: column;
      text-transform: uppercase;
      font-family: $mainFont;
      font-size: 14px;
      font-weight: 600;
      color: $primary;
    }

    .changePasswordInput {
      border-radius: 45px;
      border: 1px solid $primary;
      padding: 10px 15px;
      color: $primary;
      margin-top: 15px;
      width: 250px;
    }

    .changePasswordInput:focus {
      outline: none !important;
    }

    .changePasswordSubmitContainer {
      width: fit-content;
    }

    .passwordChangeNotification {
      font-size: 11px;
      font-weight: 400;
      text-transform: uppercase;
      font-family: $mainFont;
    }

    .passwordChangeError {
      color: red;
    }

    .passwordChangeSuccess {
      color: $primary;
    }
  }

  @media (max-width: 1132px) {
    .changePasswordForm {
      .changePasswordLabel {
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        font-family: $mainFont;
        font-size: 14px;
        font-weight: 600;
        color: $primary;
      }

      .changePasswordInput {
        width: 200px;
      }
    }
  }

  @media (max-width: 870px) {
    width: 90%;

    .changePasswordForm {
      width: 100%;

      .changePasswordLabel {
        width: 100%;
      }

      .changePasswordInput {
        width: 100%;
      }
    }
  }
}