.singlePost {
  display: flex;
  flex-direction: column;
  padding: 40px 80px;
  background-color: $light;
  border-radius: 15px;
  margin-top: 20px;
}

@media (max-width: 1250px) {
  .singlePost {
    padding: 20px 15px;
  }
}

@media (max-width: 769px) {
  .singlePost {
    border-radius: 0;
  }

  .postStatistics {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;

    .postReactionsContainer {
      display: flex;
      flex-direction: row;
      column-gap: 18px;
    }

    .reactButtonsContainer {
      display: flex;
      flex-direction: row;
      column-gap: 18px;

      .reactButton {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: transparent;
        border: none;
        column-gap: 10px;

        img {
          width: 15px;
          height: 15px;
        }

        p {
          font-size: 14px;
          font-weight: 500;
          font-family: $mainFont;
          text-transform: uppercase;
          color: $primary;
        }
      }
    }
  }
}
