.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70vh;
  margin-top: -100px;
}

.noUsersFoundContainer {
  display: flex;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;

  .noEntriesFoundLabel {
    color: $primary;
    font-weight: lighter;
    font-size: 18px;
  }
}

.mainHomeContainer {
  margin-top: 0px;
}

.banner-large-text {
  position: relative;

  h1 {
    font-size: 130px;
    font-weight: 900;
  }
}

.homeCardsWrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
  padding: 0px 0px;

  .homeCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .homeCardImg {
      display: flex;
      object-fit: cover;
      border-radius: 20px;
      margin-bottom: 20px;

      img {
        aspect-ratio: 1/1;
      }
    }

    .homeCardTitle {
      color: $primary;
      font-size: 20px;
      font-family: $mainFont;
      margin-bottom: 10px;
      font-weight: bold;
    }

    .tegsAndTextContainer {
      display: flex;
      flex-direction: column;
      // height: 120px;
      justify-content: space-between;
      margin-bottom: 40px;

      .homeCardText {
        color: $primary;
        font-size: 14px;
        font-weight: 500;
        font-family: $mainFont;
        margin-bottom: 10px;
        line-height: 1.25;
      }

      .skillsContainer {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 7.5px;
        row-gap: 7.5px;

        .singleSkillContainer {
          display: flex;
          flex-wrap: wrap;

          .skillButton {
            font-size: 11px;
            padding: 6px 10px;
            border: 2px solid $primary;
            border-radius: 30px;
            background-color: $light;
            color: $primary;
            font-weight: 900;
          }
        }
      }
    }
  }
}

.homeBanner {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;
  justify-content: center;
  column-gap: 30px;

  .homeBannerImageContainer {
    display: flex;
    width: 14%;
    height: auto;
  }

  .homeBannerLabel {
    display: flex;
    font-family: $mainFont;
    font-size: 76px;
    font-weight: 900;
    padding: 0;
    margin: 0;
    height: fit-content;
    margin-top: 14px;
    line-height: 0.75;
  }

  .homeBannerLabelRed {
    color: $red;
  }

  .homeBannerLabelBlue {
    color: $primary;
  }
}

.homeBannerMobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  justify-content: center;
  column-gap: 30px;
  align-items: center;
  row-gap: 0px;

  .homeBannerImageContainerMobile {
    display: flex;
    width: 100px;
    height: auto;
  }

  .textContainerHomeBannerMobile {
    display: flex;
    flex-direction: row;
    column-gap: 20px;

    .homeBannerLabelMobile {
      display: flex;
      font-family: $mainFont;
      font-size: 40px;
      font-weight: 900;
      padding: 0;
      margin: 0;
      height: fit-content;
      margin-top: 14px;
    }

    .homeBannerLabelRed {
      color: $red;
    }

    .homeBannerLabelBlue {
      color: $primary;
    }
  }
}

.home-banner-wrap {
  position: relative;

  .banner-inner-content {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
  }
}

.homeMainWrapper {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;

  .homeMainInner {
    padding: 0 0;
  }
}

.listOfGuidesLabelContainer {
  display: flex;
  justify-content: center;
  margin: 38px 0px;

  .listOfGuidesLabel {
    display: flex;
    font-family: $mainFont;
    font-size: 76px;
    font-weight: 900;
    padding: 0;
    margin: 0;
    height: fit-content;
    line-height: 1;
    word-spacing: 0em;
  }

  .listOfGuidesLabelRed {
    color: $red;
  }

  .listOfGuidesLabelBlue {
    color: $primary;
  }
}

.home-cards-title {
  font-size: 96px;
  font-weight: 900;
}

.list-category-title {
  margin-bottom: 20px;
  color: $primary;
  font-family: $mainFont;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 900;
}

.category-button {
  padding: 12px 20px;
  border: 1px solid $primary;
  border-radius: 30px;
  color: $primary;
}

.list-category-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  margin-right: 10px;
}

.categoryFilterButtonsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 10px;
  row-gap: 10px;
  width: 100%;

  .skillsFilterButton {
    font-weight: 900;
    font-size: 12px;
    font-family: $mainFont;
  }
}

.category-button {
  padding: 12px 20px;
  border: 2px solid $primary;
  border-radius: 30px;
  color: $primary;
  background-color: $light;
  font-size: 18px;
  font-weight: 900;
  font-family: $mainFont;
}

.homeCard {
  margin-bottom: 20px;
  // filter: drop-shadow(0px 4px 4px rgba(41, 56, 150, 0.26));

  img {
    border-radius: 20px;
  }

  &:nth-last-child(-n + 1) {
    margin-bottom: 0;
  }
}

.homeShortDescription {
  font-size: 16px;
  color: $primary;
  margin: 20px 0px 40px 0px;
  line-height: 1.5;
}

@media (max-width: 1400px) {
  .singleSkillContainer {
    button {
      font-size: 10px;
    }
  }
}

@media (max-width: 1500px) {
  .standartText {
    font-size: 16px;
  }
}

@media (max-width: 1200px) {
  .home-cards-content {
    padding-bottom: 25px;
  }

  .list-category-content {
    flex-direction: column;
    align-items: flex-start;
  }

  // .categoryFilterButtonsContainer {
  //   // button {
  //   //   margin-bottom: 15px;
  //   // }
  // }

  .categoryButtons {
    margin-bottom: 40px;
  }
}

@media (max-width: 992px) {
  .homeShortDescription {
    text-align: center;
  }

  .mainHomeContainer {
    margin-top: 0px;
    padding-top: 20px;
  }

  .homeBanner {
    column-gap: 10px;

    .homeBannerLabel {
      font-size: 48px;
    }
  }

  .listOfGuidesLabelContainer {
    margin: 40px 0px;

    .listOfGuidesLabel {
      font-size: 48px;
    }
  }

  .categoryFilterButtonsContainer {
    .skillsFilterButton {
      font-size: 15px;
      padding: 8px 12px;
      font-family: $mainFont;
    }
  }
}

@media (max-width: 700px) {
  .standartText {
    font-size: 14px;
  }

  .tegsAndTextContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
  }

  .skillsContainer {
  }
}

@media (min-width: 720px) {
  .homeCard {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 10px;

    &:nth-last-child(-n + 2) {
      margin-bottom: 0;
    }

    img {
      height: 40vw;
      //border-radius: 20px;
    }
  }

  .homeCardTitle {
    padding-right: 40px;
  }
}

@media (min-width: 1200px) {
  .homeCard {
    flex: 0 0 25%;
    max-width: 25%;
    //height: 500px;

    img {
      height: 18vw;
    }

    &:nth-last-child(-n + 4) {
      margin-bottom: 0;
    }
  }
}

@media (min-width: 2400px) {
  .homeCard {
    img {
      height: 25vh;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0px 1000px #f3f3f3 inset;
  -webkit-text-fill-color: #293896;
}

@media (max-width: 552px) {
  .homeBanner {
    .homeBannerLabel {
      font-size: 28px;
    }
  }

  .listOfGuidesLabelContainer {
    flex-wrap: wrap;
    row-gap: 5px;
    align-items: center;

    .listOfGuidesLabel {
      font-size: 40px;
    }
  }

  .list-category-title {
    font-size: 17.5px;
  }

  .homeShortDescription {
    margin: 20px 0px 20px 0px;
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
  }
}
