.error {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: 100px auto 150px auto;
    align-items: center;
    text-align: center;
    align-items: center;

    img {
        width: 100%;
        padding: 5px;
    }

    .errorLabel {
        margin-top: 20px;
        font-size: 58px;
        font-weight: 300;
        width: 100%;
        color: $primary;
    }

    .errorExplanation {
        margin-top: 10px;
        font-size: 13px;
        font-weight: 300;
        color: $primary;
        text-transform: uppercase;
        margin-bottom: 100px;
    }
}

@media (max-width: 769px) {
    .error {
        width: 80%;

        .errorLabel {
            font-size: 28px;
        }

        .errorExplanation {
            margin-bottom: 40px;
        }
    }
}