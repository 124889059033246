.standardButton {
  display: flex;
  justify-content: center;
  width: auto;
  padding: 6px 14px;
  border: none;
  border-radius: 45px;
  background-color: transparent;
  text-transform: uppercase;
  font-family: $mainFont;
  cursor: pointer;

  img {
    width: auto;
    height: auto;
  }
}

.lightMainButton {
  border: 2px solid $primary;
  background-color: $light;
  color: $primary;

}

.darkMainButton {
  border: 2px solid $primary;
  background-color: $primary;
  color: $light;
}

.postButton {
  font-size: 16px;
  font-weight: 600;
  padding: 10px 40px;
}

.loadMoreGuidesButton {
  background-color: transparent;
  border-width: 2px;
  margin: auto;
  width: fit-content;
  background-color: $light;
}

.resetPasswordButton:active {
  border: 2px solid $primary;
  background-color: $light;
  color: $primary;
}


@media (max-width: 1250px) {
  .postButton {
    .recoverPasswordButton {
      width: 100%;
    }
  }
}

@media (max-width: 1300px) {
  .homeCardFonts {
    font-size: 14px;
    font-weight: 600;
    padding: 10px 30px;
  }
}

@media (max-width: 870px) {
  .saveChangesButton {
    width: 100%;
  }
}