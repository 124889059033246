$mainContainerMB: 135px;
$mainContainerPT: 135px;
$primary: #293896;
$grey: #9D9D9D;
$transparent: transparent;
$darkLight: #F0F0F0;
$light: #fff;
$darkLight: #F3F3F3;
$middleLight: #FBFBFB;
$lightGrey: #D9D9D9;
$darkGrey: #AFB1B5;
$red: #f50000;
$mainFont: DejavuSans, sans-serif;