.postContent {
  .userContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 20px;
    margin-bottom: 20px;

    .posterProfileImage {
      width: 50px;
      height: 50px;
      border-radius: 50px;
    }

    .pinnedPosterProfileImage {
      width: 50px;
      height: 50px;
      border-radius: 50px;
    }

    .postInformationContainer {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;
      row-gap: 5px;
      padding: 5px 0;

      .posterFullName {
        font-size: 20px;
        font-weight: 900;
        font-family: $mainFont;
        color: $primary;
        text-transform: uppercase;
      }

      .pinnedPosterFullName {
        font-size: 15px;
        width: 80%;
      }

      .postDate {
        font-size: 13px;
        font-weight: 400;
        font-family: $mainFont;
        color: $primary;
        text-transform: uppercase;
      }

      .pinnedPostDate {
        font-size: 11px;
      }
    }
  }

  .postMainContent {
    .postText {
      font-size: 15px;
      font-weight: 500;
      font-family: $mainFont;
      color: $primary;
      line-height: 1.25;
    }

    .pinnedPostText {
      font-size: 13px;
      color: $primary;
      font-weight: 500;
      font-family: $mainFont;
      line-height: 1.25;
    }

    .downloadFileContainer {
      display: flex;

      .downloadFileButton {
        display: flex;
        flex-direction: row;
        width: 17.5px;
        height: 17.5px;
        margin-top: 10px;
        column-gap: 7.5px;
        color: $primary;
        align-items: flex-end;

        img {
          transform: rotate(180deg);
        }
      }
    }
  }

  .postStatistics {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 10px;
    align-items: center;

    .postReactionsContainer {
      display: flex;
      flex-direction: row;
      column-gap: 18px;
    }

    .reactButtonsContainer {
      display: flex;
      flex-direction: row;
      column-gap: 18px;
      align-items: center;
      justify-content: center;

      .reactButton {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: transparent;
        border: none;
        column-gap: 10px;

        img {
          width: 15px;
          height: 15px;
        }

        p {
          font-size: 14px;
          font-weight: 500;
          font-family: $mainFont;
          text-transform: uppercase;
          color: $primary;
        }
      }
    }
  }

  @media (max-width: 1250px) {
    .postStatistics {
      flex-direction: column;
      row-gap: 10px;

      .postReactionsContainer {
        width: 100%;
        justify-content: flex-start;
        padding-bottom: 10px;
      }

      .reactButtonsContainer {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        padding: 0 20px;
        margin-bottom: 10px;
      }
    }
  }

  @media (max-width: 769px) {
    .userContainer {
      .posterProfileImage {
        width: 50px;
        height: 50px;
      }

      .pinnedPosterProfileImage {}

      .postInformationContainer {
        .posterFullName {
          font-size: 14px;
        }

        .pinnedPosterFullName {}

        .postDate {
          font-size: 11px;
        }

        .pinnedPostDate {}
      }
    }

    .postMainContent {
      .postText {
        font-size: 14px;
      }

      .pinnedPostText {}
    }

    .postStatistics {
      margin: 0;
      padding-top: 12px;
      padding-bottom: 12px;

      .postReactionsContainer {
        display: flex;
        column-gap: 20px;
        padding: 0;
        justify-content: flex-start;
      }

      .reactButtonsContainer {
        padding: 0;

        .reactButton {
          column-gap: 5px;

          img {
            width: 15px;
            height: 15px;
          }

          p {
            font-size: 15px;
          }
        }
      }
    }
  }

  @media (max-width: 300px) {
    .postStatistics {
      .postReactionsContainer {}

      .reactButtonsContainer {
        padding: 0px;

        .reactButton {
          column-gap: 5px;

          img {
            width: 10px;
            height: 10px;
          }

          p {
            font-size: 10px;
          }
        }
      }
    }
  }
}