.loginPage {
  display: flex;
  overflow-y: hidden;
  overflow-x: hidden;

  .loginSuccessContainer {
    display: flex;
    width: 80%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    row-gap: 40px;
    margin: auto;
    margin-top: calc(30vh);
    .loginSubTitle {
      font-size: 25px;
      font-weight: 700;
      text-align: center;
      color: $primary;
      font-family: $mainFont;
    }
    .greetingContainer {
      .loginTitle {
        display: inline-flex;
        font-size: 40px;
        font-weight: 700;
        text-align: center;
        color: $primary;
        font-family: $mainFont;
        margin: 0;
      }
      .loginTitleRed {
        margin-left: 10px;
        color: red;
      }
    }
    .loginSuccessLogoContainer {
      display: flex;
      height: auto;
      width: 150px;
    }
  }
}

@media (max-width: 550px) {
  .loginPage {
    .loginSuccessContainer {
      width: 90%;
      row-gap: 30px;
      .loginSubTitle {
        font-size: 18px;
      }
      .greetingContainer {
        .loginTitle {
          font-size: 26px;
        }
      }
      .loginSuccessLogoContainer {
        width: 100px;
      }
    }
  }
}

@media (max-width: 320px) {
  .loginPage {
    .loginSuccessContainer {
      row-gap: 25px;

      .loginSubTitle {
        font-size: 15px;
      }
      .greetingContainer {
        .loginTitle {
          font-size: 21px;
        }
      }
      .loginSuccessLogoContainer {
        width: 70px;
      }
    }
  }
}
