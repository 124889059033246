.forumSearchContainer {
  margin-bottom: 0px;
}

.searchContainer {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 900;
  color: $primary;
  font-family: $mainFont;
  width: fit-content;
  height: fit-content;

  .searchLabel {
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  .inputBorder {
    border: 2px solid $primary;
    background-color: transparent;
  }

  .noBorder {
    background-color: $light;
  }

  .searchInputCotainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border-radius: 30px;
    background-color: $light;
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }

    .searchInput {
      width: 100%;
      border: none;
      border-radius: 30px;
      color: $primary;
      outline: none;
      font-weight: 900;
      font-size: 16px;
      padding-left: 15px;
      background-color: $light;
      font-family: $mainFont;
    }

    .searchInput::placeholder {
      color: $primary;
      font-family: $mainFont;
    }

    .searchIcon {
      display: inline-block;
      margin-right: 15px;
      width: 25px;
      background-color: $light;

    }

    .guideSearchInput {
      font-weight: 900;
      padding: 7.5px 15px;
      font-size: 15px;
      background-color: $light;
    }
  }


  /*@media (max-width: 769px) {
    .searchInputCotainer {
      border-radius: 0px;
    }
  }*/
  @media (max-width: 550px) {
    font-size: 17.5px;

  }
}