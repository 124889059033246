.mr-8 {
  margin-right: 40px;
}

.mr-2 {
  margin-right: 10px;
}

.mb-2 {
  margin-bottom: 10px;
}

.mb-3 {
  margin-bottom: 15px;
}

.mb-4 {
  margin-bottom: 20px;
}
