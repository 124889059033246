.loading {
  width: 3rem;
  height: 3rem;
  border: 3px solid $primary;
  border-radius: 50%;
  border-top-color: $light;
  animation: spinner 2s linear infinite;
}

.loading_small {
  width: 1.5rem;
  height: 1.5rem;
  border: 1.5px solid $primary;
  border-radius: 50%;
  border-top-color: $light;
  animation: spinner 2s linear infinite;
}

.loading-center {
  margin: auto auto;
}

.loading-vertical-center {
  display: flex;
  margin-top: 37.5%;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}