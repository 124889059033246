.formRegisterWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: #fff;
}

.registerText {
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}

.registerTitle {
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.registerFormFlexible {
  display: flex;
  flex-wrap: wrap;

  input {
    width: 100%;
    padding: 16px 20px;
    border: 2px solid #e5e5e5;
    border-radius: 45px;
    color: #293896;
    font-size: 18px;
    font-weight: 700;
    font-family: $mainFont;

    &::placeholder {
      font-size: 13px;
      font-weight: 700;
      color: $primary;
      font-family: $mainFont;
    }
  }

  .sbmCol {
    margin: 30px 0;
  }

  input[type="submit"] {
    background-color: #293896;
    color: #fff;
    cursor: pointer;
  }
}

.registerCol {
  width: 100%;
  margin-bottom: 10px;
}

.formRegisterContent {
  padding: 40px 25px;
}

.registerFormContainer {
  margin: 0 auto;
  padding: 40px;
  box-shadow: 0px 4px 4px rgba(41, 56, 150, 0.1);
  border-radius: 30px;
}

.registerCol {
  width: 100%;
  margin-bottom: 10px;

  input {
    width: 100%;
    padding: 16px 20px;
    border: 2px solid #e5e5e5;
    border-radius: 45px;
    color: #293896;
    font-size: 18px;
    font-weight: 700;
    font-family: $mainFont;
  }
}

@media (max-width: 600px) {
  .formRegisterContent {
    padding: 40px 10px;
  }

  .registerFormContainer {
    margin: 0 auto;
    padding: 40px 10px;
    box-shadow: 0px 4px 4px rgb(41 56 150 / 10%);
    border-radius: 30px;
  }
}
